import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex justify-between" }
const _hoisted_2 = { class: "text-lg font-semibold text-gray-900" }
const _hoisted_3 = { class: "flex items-center justify-between pb-4 border-b" }
const _hoisted_4 = { class: "flex items-center text-lg font-semibold text-gray-900" }
const _hoisted_5 = { class: "flex py-4" }
const _hoisted_6 = { class: "font-medium text-gray-900" }
const _hoisted_7 = { class: "flex flex-row items-center justify-end" }
const _hoisted_8 = { class: "w-full p-10" }
const _hoisted_9 = { class: "text-xl font-bold text-gray-900 mb-3" }
const _hoisted_10 = { class: "grid grid-cols-1 pt-2" }
const _hoisted_11 = { class: "w-full max-w-124 z-10" }
const _hoisted_12 = { class: "mt-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_ButtonLink = _resolveComponent("ButtonLink")!
  const _component_BaseTextInput = _resolveComponent("BaseTextInput")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_SpecialityButton = _resolveComponent("SpecialityButton")!
  const _component_PopoverButton = _resolveComponent("PopoverButton")!
  const _component_BasePopover = _resolveComponent("BasePopover")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_BasePagination = _resolveComponent("BasePagination")!
  const _component_BaseCard = _resolveComponent("BaseCard")!
  const _component_BaseSelect = _resolveComponent("BaseSelect")!
  const _component_BaseChip = _resolveComponent("BaseChip")!
  const _component_BaseModal = _resolveComponent("BaseModal")!
  const _directive_allow = _resolveDirective("allow")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", null, [
        _createElementVNode("button", {
          type: "button",
          class: "font-semibold text-gray-500",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.cancel()))
        }, [
          _createVNode(_component_BaseIcon, { name: "arrow-left" }),
          _createTextVNode(" " + _toDisplayString(_ctx.$t('platform.role.all')), 1)
        ]),
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.name), 1)
      ]),
      _createElementVNode("div", null, [
        _withDirectives((_openBlock(), _createBlock(_component_ButtonLink, {
          to: `../../roles/${_ctx.roleId}`,
          color: "secondary",
          "left-icon": "users-multiple"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('platform.role.edit')), 1)
          ]),
          _: 1
        }, 8, ["to"])), [
          [_directive_allow, 'role:update']
        ])
      ])
    ]),
    _createVNode(_component_BaseCard, { class: "mt-8" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.filtered ? _ctx.$t('platform.common.search-results') : _ctx.$t('platform.user.users')), 1),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_BaseTextInput, {
              modelValue: _ctx.search,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.search) = $event)),
              icon: "search",
              placeholder: _ctx.$t('platform.common.search'),
              size: "small",
              onKeyup: _ctx.updateSearchDebounced
            }, null, 8, ["modelValue", "placeholder", "onKeyup"]),
            _withDirectives((_openBlock(), _createBlock(_component_BaseButton, {
              class: "ml-4",
              "left-icon": "add",
              type: "button",
              disabled: !_ctx.filteredUsers.length,
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setUserAssignModalVisibility(true)))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('platform.user.assign')), 1)
              ]),
              _: 1
            }, 8, ["disabled"])), [
              [_directive_allow, 'user-role:assign']
            ])
          ])
        ]),
        _createVNode(_component_DataTable, {
          loading: _ctx.loading,
          columns: [
          { name: 'name', label: _ctx.$t('platform.common.name'), sortable: true },
          { name: 'actions', label: '', align: 'right' }
        ],
          rows: _ctx.rows,
          sort: _ctx.sort,
          class: "w-full mt-6",
          "show-table-header": false
        }, {
          "cell.name": _withCtx(({ row }) => [
            _createElementVNode("div", _hoisted_6, _toDisplayString(row.given_name) + " " + _toDisplayString(row.family_name), 1)
          ]),
          "cell.actions": _withCtx(({ row }) => [
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_BasePopover, { position: "left" }, {
                button: _withCtx(() => [
                  _createVNode(_component_SpecialityButton, {
                    "data-dusk": `user-${row.id}-popover`
                  }, null, 8, ["data-dusk"])
                ]),
                menu: _withCtx(() => [
                  _createVNode(_component_PopoverButton, {
                    onClick: ($event: any) => (_ctx.editUser(row.id))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('platform.user.edit')), 1)
                    ]),
                    _: 2
                  }, 1032, ["onClick"]),
                  _withDirectives((_openBlock(), _createBlock(_component_PopoverButton, {
                    "data-dusk": `user-${row.id}-unassign`,
                    onClick: ($event: any) => (_ctx.deleteRoleAssignment(row.id))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('platform.role.unassign')), 1)
                    ]),
                    _: 2
                  }, 1032, ["data-dusk", "onClick"])), [
                    [_directive_allow, 'user-role:revoke']
                  ])
                ]),
                _: 2
              }, 1024)
            ])
          ]),
          _: 1
        }, 8, ["loading", "columns", "rows", "sort"]),
        (_ctx.total && _ctx.total > _ctx.perPage)
          ? (_openBlock(), _createBlock(_component_BasePagination, {
              key: 0,
              "model-value": _ctx.page,
              "page-size": _ctx.perPage,
              length: _ctx.total,
              "items-name": _ctx.$t('platform.user.users').toLowerCase(),
              "onUpdate:modelValue": _ctx.changePage
            }, null, 8, ["model-value", "page-size", "length", "items-name", "onUpdate:modelValue"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    (_ctx.showUsersModal)
      ? (_openBlock(), _createBlock(_component_BaseModal, {
          key: 0,
          "data-dusk": "select-user-modal",
          class: "inline-block bg-white",
          onClose: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showUsersModal = false))
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("h1", _hoisted_9, _toDisplayString(_ctx.$t('platform.user.assign')), 1),
              _createElementVNode("div", _hoisted_10, [
                _createVNode(_component_BaseSelect, {
                  "data-dusk": "select-user",
                  class: "w-124 max-h-75",
                  placeholder: _ctx.$t('platform.user.select'),
                  options: _ctx.availableUsers,
                  "onUpdate:modelValue": _ctx.updateSelectedUser
                }, null, 8, ["placeholder", "options", "onUpdate:modelValue"]),
                _createElementVNode("div", _hoisted_11, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedUsers, (user) => {
                    return (_openBlock(), _createBlock(_component_BaseChip, {
                      key: user.id,
                      class: "mr-2 my-2 mt-3",
                      removable: "",
                      onRemoved: ($event: any) => (_ctx.removeUser(user))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(user.label), 1)
                      ]),
                      _: 2
                    }, 1032, ["onRemoved"]))
                  }), 128))
                ])
              ]),
              _createElementVNode("div", _hoisted_12, [
                _createVNode(_component_BaseButton, {
                  size: "large",
                  onClick: _ctx.createRoleAssignment
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('platform.common.assign')), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"]),
                _createVNode(_component_BaseButton, {
                  size: "large",
                  color: "ghost",
                  class: "ml-4",
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.setUserAssignModalVisibility(false)))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('platform.common.cancel')), 1)
                  ]),
                  _: 1
                })
              ])
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}